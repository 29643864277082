import React from 'react'
import {ChakraProvider} from '@chakra-ui/react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {createRoot} from 'react-dom/client'
import makeTheme from '../theme/makeTheme'
import LoginForm from './pages/LoginForm'
import initSentry from "services/sentry"

initSentry()

const queryClient = new QueryClient()
const globalStyles = {
	body: {
		bg: 'gray.100',
	},
}

function App() {
	return (
		<ChakraProvider theme={makeTheme(globalStyles)}>
			<QueryClientProvider client={queryClient}>
				<LoginForm />
			</QueryClientProvider>
		</ChakraProvider>
	)
}

if (document.getElementById('root')) {
	const container = document.getElementById('root')

    if (container) {
        const root = createRoot(container) // Ensure the non-null assertion is removed or handled properly.
        root.render(<App />)
    }
}
